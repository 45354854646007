import styled from 'styled-components';


export const HtmlSitemapLink = styled.a`
font-size: 18px;
text-decoration: none;
color: #007DC3;
`;

export const HtmlSitemapMainDiv = styled.div`
margin: 100px;
display: flex;
flex-direction: column;
@media (max-width: 768px) {
    margin:145px 70px;
}
`;

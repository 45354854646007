import React from 'react';
import { graphql } from 'gatsby';
import 'bootstrap/dist/css/bootstrap.css';
import NavigatioBar from '../components/navigationBar/mainMenu/navigationBar';
import Footer from '../components/footer/footer';
import GlobalStyles from '../styles/GlobalStyles';
import { Helmet } from 'react-helmet';
import {
    HtmlSitemapMainDiv,
    HtmlSitemapLink,
} from '../styles';
import { addTrailingSlash} from '../utils';

const HtmlSitemap = ({ data }) => {
    const allSlugs = [
        ...data?.allContentfulSolEcolution?.edges
            .filter((edge) => {
            if (
                edge?.node?.slug &&
                !edge?.node?.slug?.includes('navigation') &&
                !edge?.node?.slug?.includes('footer')
            ) {
                return edge?.node?.slug;
            }
            })
            .map((edge) => {
            return {
                slug: addTrailingSlash(edge?.node?.slug),
                title: edge?.node?.title,
            };
            }),
        ].sort((a, b) => a?.slug?.localeCompare(b?.slug));

        const SlugList = allSlugs.filter(function (x, i, a) {
            return a.indexOf(x) === i;
        });

    return <>
    <GlobalStyles />
    <Helmet>
        <title>Site Map | Sol Ecolution</title>
    </Helmet>
    <NavigatioBar />
    <HtmlSitemapMainDiv>
    {SlugList.map((slug, index) => (
        <span>
        <HtmlSitemapLink style={{    
            color:' #007DC3 !important'
            }} 
            href={process.env.GATSBY_DOMAIN_URL + slug?.slug}>
            {slug?.title}
        </HtmlSitemapLink>
        </span>
        ))}
    </HtmlSitemapMainDiv>
    <Footer />
    </>
}

export default HtmlSitemap;
export const pageQuery = graphql`
query SitemapQuery {
    allContentfulSolEcolution(
        filter: {node_locale: {eq: "en-US"}, hasIndex: {eq: true}}
    ) {
        edges {
        node {
            slug
            title
            name
        }
        }
    }
    }
`;